import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const IndexPage = () => {

  const { allStrapiArticle, strapiGlobal, strapiHome } = useStaticQuery(graphql`
    query {
      allStrapiArticle(limit: 3) {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
        logoSecondary {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      strapiHome {
        video {
          localFile {
            localURL
          }
        }
        videoPoster {
          localFile {
            localURL
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Cycladic Artist Residency" }} />
      <main>
        <div class="w-full">
          <div class="">
            <video controls={true} loop="true" autoplay="autoplay" playsInline muted poster={strapiHome.videoPoster?.localFile.localURL}>
              <source src={strapiHome.video.localFile.localURL} />
            </video>
          </div>
        </div>
        <div class="container mb-10 mt-20">
          <div class="font-light text-tpBlue text-4xl border-b-2 border-tpBlue p-2">About us</div>
          <div class="mt-10 text-2xl font-light text-tpBlackish tracking-wider leading-10">
            Thermia Project is an art residency program in Kythnos, founded and directed by Odette Kouzou. Established in 2022, the program focuses on creating a dialogue between contemporary art and local tradition. By prioritising the implementation of a set of tailor-made activities curated for each participant, the invited artists are warmly welcomed and offered the time, space, and resources to focus on their work and prepare for a group show at the end of their residency. The program is designed to encourage artists to explore their artistic vision and foster new ideas by working alongside their peers. Thermia Project is dedicated to support the local communities and to create engagement with contemporary art.
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage